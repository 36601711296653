import React from 'react';
import { Icon, Tooltip } from '@cognitiv/cassiopeia-ui';
import classNames from 'classnames';

import cn from 'components/menu/components/Components.module.scss';
import { menu_theme } from 'components/menu/components/theme';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { MenuActionProps } from 'components/menu/types';

export const MenuAction = ({ onClick, icon, label, children, className, message = '' }: MenuActionProps) => {
  const { is_menu_open } = useMenuContext();
  return (
    <Tooltip content={!is_menu_open ? message : ''}>
      <div className={classNames(cn.action, className, { [cn.open]: is_menu_open })} onClick={onClick}>
        {icon && <Icon icon={icon} theme={menu_theme.large} />}
        {children}
        <p className={cn.label}>{label}</p>
      </div>
    </Tooltip>
  );
};
