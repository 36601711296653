import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { faBullhorn, faCloudArrowDown, faPeopleGroup, faSearch, faSquareTerminal, faTableLayout } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { updateModal } from 'ducks/modals/slices';
import { selectSettings } from 'ducks/settings/selectors';
import { PWA_LOCAL_STORAGE_KEY } from 'hooks/useAddToHomeScreenPrompt';
import { THEME, useGlobalTheme } from 'hooks/useGlobalTheme';
import { GROUP, PAGE } from 'hooks/useMenu';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { GEMINI, HYPERION, LIBRA, LUNA, ORION, TITAN, VIRGO } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { MenuAction } from 'components/menu/components/MenuAction';
import { MenuGroup } from 'components/menu/components/MenuGroup';
import { MenuPage } from 'components/menu/components/MenuPage';
import { MenuSwitch } from 'components/menu/components/MenuSwitch';
import { useMenuContext } from 'components/menu/context/MenuContext';
import logoClose from 'components/menu/logo/logo-animation-close.json';
import logoOpen from 'components/menu/logo/logo-animation-open.json';
import cn from 'components/menu/Menu.module.scss';

const { REACT_APP_VERSION } = process.env;

export const Menu = () => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector(selectSettings);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [hidden, setHidden] = useState(false);

  const { group, getApplicationPage, is_menu_open, setMenu, is_installed } = useMenuContext();
  const [install_pwa, setInstallPWA] = useState(false);

  const { switchTheme } = useGlobalTheme();

  useEffect(() => {
    const is_pwa_installed = localStorage.getItem(PWA_LOCAL_STORAGE_KEY);
    if (!is_pwa_installed && is_installed) {
      setInstallPWA(true);
    }
  }, [is_installed]);

  const installPWA = useCallback(async () => {
    const result = await is_installed?.prompt();
    if (result?.outcome === 'accepted') {
      localStorage.setItem(PWA_LOCAL_STORAGE_KEY, 'true');
      setInstallPWA(false);
    }
  }, [is_installed]);

  const onSearch = useCallback(() => {
    dispatch(updateModal({ search: { is_open: true } }));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => setHidden(true), 300);
  }, [is_menu_open]);

  useEffect(() => {
    // DEV-NOTE: Prevent animation from jumping on first render by setting it to the end position
    if (lottieRef.current) {
      const { goToAndStop } = lottieRef.current;
      goToAndStop(is_menu_open ? 250 : 200);
    }
  }, []);

  return (
    <nav className={classNames(cn.menu, { [cn.open]: is_menu_open, [cn.hidden]: hidden })}>
      <Link className={classNames(cn.image, { [cn.open]: is_menu_open })} to={LUNA.APPLICATION.path}>
        <Lottie lottieRef={lottieRef} animationData={is_menu_open ? logoOpen : logoClose} loop={0} style={{ width: 200, height: 50 }} />
      </Link>
      <div className={cn.toggle}>
        <MenuAction
          onClick={() => {
            setHidden(false);
            setMenu(!is_menu_open);
          }}
          label={is_menu_open ? 'Collapse Menu' : 'Expand Menu'}
          message={is_menu_open ? 'Collapse Menu' : 'Expand Menu'}
          icon={faBars}
        />
        <MenuAction label="Go To..." icon={faSearch} onClick={onSearch} message="&#8984; + K" />
      </div>
      <div className={classNames(cn.grow, cn.mainMenuItems)}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <div className={cn.position} style={{ height, width }}>
                <MenuGroup
                  to={`${VIRGO.APPLICATION.path}/${VIRGO.CAMPAIGNS.path}`}
                  label="Curation"
                  icon={faPeopleGroup}
                  selected={group === GROUP.VIRGO}
                >
                  <MenuPage
                    label="Campaigns"
                    to={`${VIRGO.APPLICATION.path}/${VIRGO.CAMPAIGNS.path}`}
                    selected={group === GROUP.VIRGO && getApplicationPage(3) === PAGE.CAMPAIGNS}
                  />
                  <MenuPage
                    label="Deals"
                    to={`${VIRGO.APPLICATION.path}/${VIRGO.DEALS.path}`}
                    selected={group === GROUP.VIRGO && getApplicationPage(3) === PAGE.DEALS}
                  />
                </MenuGroup>
                <MenuGroup
                  to={`${ORION.APPLICATION.path}/${ORION.CAMPAIGNS.path}`}
                  label="Contextual"
                  icon={faSquareTerminal}
                  selected={group === GROUP.ORION}
                >
                  <MenuPage
                    label="Campaigns"
                    to={`${ORION.APPLICATION.path}/${ORION.CAMPAIGNS.path}`}
                    selected={group === GROUP.ORION && getApplicationPage(3) === PAGE.CAMPAIGNS}
                  />
                  <MenuPage
                    label="Segments"
                    to={`${ORION.APPLICATION.path}/${ORION.SEGMENTS.path}`}
                    selected={group === GROUP.ORION && getApplicationPage(3) === PAGE.SEGMENTS}
                  />
                  <MenuPage
                    label="Deals"
                    to={`${ORION.APPLICATION.path}/${ORION.DEALS.path}`}
                    selected={group === GROUP.ORION && getApplicationPage(3) === PAGE.DEALS}
                  />
                  <MenuPage
                    label="Segment Domain Lists"
                    to={`${ORION.APPLICATION.path}/${ORION.DOMAIN_LISTS.path}`}
                    selected={group === GROUP.ORION && getApplicationPage(3) === PAGE.DOMAIN_LISTS}
                  />
                </MenuGroup>
                <MenuGroup
                  to={`${GEMINI.APPLICATION.path}/${GEMINI.CAMPAIGNS.path}`}
                  label="Core"
                  icon={faBullhorn}
                  selected={group === GROUP.GEMINI}
                >
                  <MenuPage
                    label="Campaigns"
                    to={`${GEMINI.APPLICATION.path}/${GEMINI.CAMPAIGNS.path}`}
                    selected={group === GROUP.GEMINI && getApplicationPage(3) === PAGE.CAMPAIGNS}
                  />
                </MenuGroup>
                <MenuGroup
                  to={`${HYPERION.APPLICATION.path}/${HYPERION.CUSTOM_REPORTS.path}`}
                  label="Reporting"
                  icon={faTableLayout}
                  selected={group === GROUP.REPORTING}
                  active={
                    getApplicationPage(2) === PAGE.REPORTING && getApplicationPage(3) === PAGE.DASHBOARDS && getApplicationPage(4) === undefined
                  }
                >
                  <MenuPage
                    label="CS Campaign Tracker"
                    to={`${HYPERION.APPLICATION.path}/${HYPERION.CS_CAMPAIGN_TRACKER.path}/${HYPERION.CS_CAMPAIGN_TRACKER.ALL_REGIONS.path}`}
                    selected={group === GROUP.REPORTING && getApplicationPage(5) === PAGE.CS_CAMPAIGN_TRACKER && getApplicationPage(4) === '5'}
                  />
                  <MenuPage
                    label="DS Campaign Tracker"
                    to={`${HYPERION.APPLICATION.path}/${HYPERION.DS_CAMPAIGN_TRACKER.path}`}
                    selected={group === GROUP.REPORTING && getApplicationPage(5) === PAGE.DS_CAMPAIGN_TRACKER && getApplicationPage(4) === '2'}
                  />
                  <MenuPage
                    label="Custom Reports"
                    to={`${HYPERION.APPLICATION.path}/${HYPERION.CUSTOM_REPORTS.path}`}
                    selected={group === GROUP.REPORTING && getApplicationPage(3) === PAGE.REPORTS}
                  />
                  <div className={cn.break} style={{ margin: '8px 0px' }} />
                  <MenuPage
                    label="Inbound Report Inbox"
                    to={`${HYPERION.APPLICATION.path}/${HYPERION.INBOUND_REPORT_INBOX.path}`}
                    selected={group === GROUP.REPORTING && getApplicationPage(3) === PAGE.INBOUND_REPORT_INBOX}
                  />
                  <MenuPage
                    label="Import Configurations"
                    to={`${HYPERION.APPLICATION.path}/${HYPERION.IMPORT_CONFIGURATIONS.path}`}
                    selected={group === GROUP.REPORTING && getApplicationPage(3) === PAGE.IMPORT_CONFIGURATIONS}
                  />
                  <MenuPage
                    label="DSP Line Item - Deal Mappings"
                    to={`${HYPERION.APPLICATION.path}/${HYPERION.DSP_LINE_ITEM_DEAL_MAPPINGS.path}`}
                    selected={group === GROUP.REPORTING && getApplicationPage(3) === PAGE.DSP_LINE_ITEM_DEAL_MAPPINGS}
                  />
                </MenuGroup>
                <MenuGroup
                  to={LUNA.APPLICATION.path}
                  label="Global"
                  icon={faGrid2}
                  selected={group === GROUP.CONFIGURATION}
                  active={getApplicationPage(2) === PAGE.EMPTY}
                >
                  <MenuPage
                    label="Partners"
                    to={`${TITAN.APPLICATION.path}/${TITAN.PARTNERS.path}`}
                    selected={getApplicationPage(2) === PAGE.PARTNERS}
                  />
                  <MenuPage
                    label="Advertisers"
                    to={`${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}`}
                    selected={getApplicationPage(2) === PAGE.ADVERTISERS}
                  />
                  <MenuPage
                    label="Event Sources"
                    to={`${TITAN.APPLICATION.path}/${TITAN.EVENT_SOURCES.path}`}
                    selected={getApplicationPage(2) === PAGE.EVENT_SOURCES}
                  />
                  <MenuPage
                    label="Creatives"
                    to={`${TITAN.APPLICATION.path}/${TITAN.CREATIVES.path}`}
                    selected={getApplicationPage(2) === PAGE.CREATIVES}
                  />
                  <MenuPage
                    label="Personas"
                    to={`${HYPERION.APPLICATION.DEFAULT.path}/${HYPERION.PERSONAS.path}`}
                    selected={getApplicationPage(2) === PAGE.PERSONAS}
                  />
                  <div className={cn.break} style={{ margin: '8px 0px' }} />
                  <MenuPage
                    label="DSP Seat Identifiers"
                    to={`${LIBRA.APPLICATION.path}/${LIBRA.DSP_SEAT_IDENTIFIERS.path}`}
                    selected={getApplicationPage(2) === PAGE.DSP_SEAT_IDENTIFIERS}
                  />
                  <MenuPage
                    label="Domain Lists"
                    to={`${LIBRA.APPLICATION.path}/${LIBRA.DOMAIN_LISTS.path}`}
                    selected={getApplicationPage(2) === PAGE.DOMAIN_LISTS}
                  />
                  <MenuPage
                    label="Notifications"
                    to={`${TITAN.APPLICATION.path}/${TITAN.NOTIFICATIONS.path}`}
                    selected={getApplicationPage(2) === PAGE.NOTIFICATIONS}
                  />
                  {/* <MenuPage
                    label="Filters"
                    to={`${POLLUX.APPLICATION.path}/${POLLUX.FILTERS.path}`}
                    selected={getApplicationPage(2) === PAGE.FILTERS}
                  /> */}
                </MenuGroup>
              </div>
            );
          }}
        </AutoSizer>
      </div>
      <div className={cn.break} />
      {install_pwa && <MenuAction label="Install PWA" message="Install PWA" icon={faCloudArrowDown} onClick={installPWA} />}
      <div className={cn.break} />
      <MenuAction
        onClick={switchTheme}
        label={`${theme === THEME.DARK ? 'Light' : 'Dark'} Mode`}
        message={`${theme === THEME.DARK ? 'Light' : 'Dark'} Mode`}
        className={cn.switchWrapper}
      >
        <MenuSwitch checked={theme === THEME.DARK} />
      </MenuAction>
      <span className={classNames(cn.version, { [cn.open]: is_menu_open })}>v {REACT_APP_VERSION}</span>
    </nav>
  );
};
