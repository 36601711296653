import { TitanVirtualEventSource } from '@cognitiv/galaxy-api';

export const titan_virtual_event_source_default: TitanVirtualEventSource = {
  advertiser_id: 0,
  advertiser_name: '',
  event_source_id: 0,
  event_source_name: '',
  event_source_identifier: '',
  event_source_name_formatted: '',
  event_source_type_id: 0,
  event_source_type_name: '',
  parent_event_source_id: 0,
  parent_event_source_name: '',
  parent_event_source_name_formatted: '',
  event_source_category_name: '',
  event_source_category_id: null,
  event_source_state_id: 0,
  event_source_state_name: '',
  state_id: 0,
  state_name: '',
  conditions: [],
  revision_id: null,
  condition_count: 0,
  condition_count_abbr_formatted: '',
  uuid: '',
  today_count: 0,
  yesterday_count: 0,
  this_week_count: 0,
  this_month_count: 0,
  all_time_count: 0,
  daily_average_count: 0,
  weekly_average_count: 0,
  monthly_average_count: 0,
  today_count_abbr_formatted: '',
  yesterday_count_abbr_formatted: '',
  this_week_count_abbr_formatted: '',
  this_month_count_abbr_formatted: '',
  all_time_count_abbr_formatted: '',
  daily_average_count_abbr_formatted: '',
  weekly_average_count_abbr_formatted: '',
  monthly_average_count_abbr_formatted: '',
  note: null,
};
