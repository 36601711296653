import React, { useRef } from 'react';
import { Action, CaptureKeyEvent, InputEvent, InputText, Modal } from '@cognitiv/cassiopeia-ui';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { selectModal } from 'ducks/modals/selectors';
import { useSearchContext } from 'modals/search/context/SearchCommandContext';
import { Options } from 'modals/search/options/Options';
import { useAppSelector } from 'store/hooks';

import cn from 'modals/search/Search.module.scss';

const theme = {
  modal: { modal: { general: { gap: 0, padding: '0px 0px 0px 0px', min_height: 'auto' } } },
  field: {
    field: {
      background: { hover: 'none' },
      border: {
        normal: '1px solid rgba(0,0,0,0)',
        hover: '1px solid rgba(0,0,0,0)',
        focus: '1px solid rgba(0,0,0,0)',
        pressed: '1px solid rgba(0,0,0,0)',
        selected: '1px solid rgba(0,0,0,0)',
        disabled: '1px solid rgba(0,0,0,0)',
        error: '1px solid rgba(0,0,0,0)',
      },
    },
  },
};

export const Search = () => {
  const { is_open } = useAppSelector((state) => selectModal(state, 'search'));

  const divRef = useRef<HTMLDivElement | null>(null);

  const { search, options, recently_viewed_pages, navigation_pages, onKeyDown, setSearch, onClose } = useSearchContext();

  const is_empty = !options.length;

  return (
    <Modal identifier="search" is_open={is_open} width={700} onClose={onClose} theme={theme.modal} backdrop_close>
      <CaptureKeyEvent refs={[divRef]} onKeyDown={onKeyDown}>
        <div ref={divRef} tabIndex={-1}>
          <Modal.Header dismiss={false}>
            <div className={cn.close}>
              <Action
                left_icon={faXmark}
                onClick={() => onClose()}
                variant="plain"
                theme={{ icon: { height: '16px', width: '16px', font_size: '16px' } }}
              />
            </div>
            <div className={cn.container}>
              <InputText
                key={search}
                auto_focus
                transparent
                left_icon={faSearch}
                onChange={(item: InputEvent) => setSearch(item.value)}
                value={search}
                width="100%"
                height="50px"
                debounce={300}
                theme={theme.field}
              />
              <Action variant="plain" disabled={!search.length} onClick={() => setSearch('')}>
                Clear
              </Action>
              <div className={cn.line} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className={classNames(cn.scrollable, { [cn.resetOffsets]: is_empty })}>
              <Options pages={recently_viewed_pages} title="Recently Viewed" />
              <Options pages={navigation_pages} title="Navigation" />
              {is_empty && <span className={cn.empty}>No Matching Results</span>}
            </div>
          </Modal.Body>
        </div>
      </CaptureKeyEvent>
    </Modal>
  );
};
